import React, { useEffect, useState } from "react";
import axios from "axios";
import Message from "./message";
import { ClipLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import PlacesCard from "./components/PlacesCard";
import AddressCard from "./components/AddressCard";

const Screen = () => {
  const [eventDetailes, setEventDetailes] = useState(null);
  const [inviteeCount, setInviteeCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const { eventId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      if (!eventId || !(parseInt(eventId) > 0)) {
        setIsNotFound(true);
        setIsLoading(false);
        return;
      }
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/hangouts/hangout-events-details/${eventId}/`
        );
        setEventDetailes(response?.data);
      } catch (error) {
        if (error?.response?.status === 404) {
          setIsNotFound(true);
        } else {
          setIsError(true);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [eventId]);

  useEffect(() => {
    let count = 0;
    if (
      Array.isArray(eventDetailes?.friends) &&
      eventDetailes?.friends?.length
    ) {
      count += eventDetailes?.friends?.length;
    }
    if (
      Array.isArray(eventDetailes?.non_niteowl_friends) &&
      eventDetailes.non_niteowl_friends.length
    ) {
      count += eventDetailes.non_niteowl_friends.length;
    }
    setInviteeCount(count);
  }, [eventDetailes]);

  const onSubmit = () => {
    window.location.href = eventDetailes?.deeplinks[0]?.deeplink; // Replace 'url1' with your Android URL
  };

  const formatDateTime = (dateTimeString) => {
    const dateOptions = { weekday: "short", month: "short", day: "numeric" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };

    const dateTime = new Date(dateTimeString);
    const formattedDate = dateTime.toLocaleDateString("en-US", dateOptions);
    const formattedTime = dateTime.toLocaleTimeString("en-US", timeOptions);

    return `${formattedDate}  |  ${formattedTime}`;
  };

  const originalDateTime = eventDetailes?.datetime;
  const formattedDateTime = formatDateTime(originalDateTime);
  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full height of the viewport
        }}
      >
        <ClipLoader loading={isLoading} size={50} color="#ECAA00" />
      </div>
    );
  if (isError)
    return <Message text="Something went wrong. Please try again!" />;
  if (!eventId || isNotFound) return <Message text="Event not found!" />;
  return (
    <div>
      <div
        style={{
          padding: "12px 16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            color: "#BBBDC5",
            fontSize: 10,
          }}
        >
          Install NiteOwl app to view invite and to respond
        </div>
        <button
          style={{
            background: "#ECAA00",
            color: "#121420",
            fontSize: 9,
            padding: "4px 6px 4px 6px",
            borderRadius: 16,
            border: "1px solid #ECAA00",
          }}
          className="btnText"
          onClick={() => onSubmit()}
        >
          Install App
        </button>
      </div>
      <div style={{ height: 24, textAlign: "center", margin: 14 }}>
        <img src={require("./Images/niteowllogo.png")} alt="" />
      </div>
      <div
        className="content"
        style={{
          background:
            "linear-gradient(205.88deg, #2F8D9A 7.26%, #4161B5 85.36%)",
        }}
      >
        <div>
          <div
            style={{
              color: "#E8E8EC",
              fontSize: 20,
              alignSelf: "flex-start",
              fontWeight: 800,
            }}
          >
            {eventDetailes?.name}
          </div>
          <button
            style={{
              background: "#5398B0",
              border: "1px solid #5398B0",
              color: "#152A2F",
              fontSize: 8,
              float: "right",
              padding: "4px",
              borderRadius: 16,
              display: "inline-flex",
              alignItems: "center",
            }}
            className="btnText"
          >
            <img src={require("./Images/lock.png")} alt="" />
            <div style={{ marginLeft: 2 }}>Private</div>
          </button>
        </div>
        <div style={{ marginTop: 10 }}>
          <div style={{ color: "#D1D2D8", fontWeight: 600 }}>
            {formattedDateTime}
          </div>
        </div>
      </div>
      <div style={{ margin: 20 }}>
        <ul
          className="horizontal-list"
          style={{
            listStyleType: "none",
            padding: 0,
            margin: 0,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <li
            style={{
              color: "#ECAA00",
              borderBottom: "1px solid #ECAA00",
              paddingBottom: 10,
              fontSize: 13,
            }}
          >
            <div>Overview</div>
          </li>
        </ul>
      </div>
      <div style={{ margin: 20 }}>
        <div
          style={{
            background: "#1F2335",
            borderRadius: 16,
            padding: 16,
            marginBottom: 10,
          }}
        >
          <div
            className="card"
            style={{
              marginBottom: 10,
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <img src={require("./Images/friends.png")} alt="" />
            <div
              style={{
                color: "#D1D2D8",
                fontSize: 14,
                fontWeight: 700,
                marginLeft: 10,
              }}
            >
              Invitees ({inviteeCount})
            </div>
          </div>

          <div className="card" style={{ marginBottom: 10 }}>
            <div
              style={{
                color: "#D1D2D8",
                fontSize: 14,
                fontWeight: 700,
              }}
            >
              Your friend is inviting you for NiteOut!
            </div>
          </div>
          <div className="card" style={{ marginBottom: 10 }}>
            {inviteeCount > 1 && (
              <div
                style={{
                  color: "#BBBDC5",
                  fontSize: 11,
                  fontWeight: 400,
                }}
              >
                {inviteeCount - 1 + " others are invited"}{" "}
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ margin: 20 }}>
        {eventDetailes?.custom_location?.description ? (
          <AddressCard address={eventDetailes?.custom_location?.description} />
        ) : (
          <PlacesCard venues={eventDetailes?.venues} />
        )}
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#1D2132",
          padding: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <button
          onClick={() => onSubmit()}
          style={{
            padding: "12px 20px 12px 20px",
            width: "100%",
            backgroundColor: "#ECAA00",
            display: "inline-flex",
            alignItems: "center",
            borderRadius: "8px",
            border: "1px solid #ECAA00",
            height: "40px",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              color: "#121420",
              fontSize: 14,
              fontWeight: 700,
            }}
          >
            Download NiteOwl to RSVP
          </div>
        </button>
      </div>
    </div>
  );
};

export default Screen;
