import { FC, memo } from "react";
import { Card, CardBody, CardHeader } from "./ui/cards";
import locationImg from "../Images/location.png";
import { AddressCardProps } from "../types/addressCard.types";

const AddressCard: FC<AddressCardProps> = memo(({ address = "" }) => {
  if (!address) return null;
  return (
    <Card>
      <CardHeader
        icon={<img src={locationImg} alt="Places" />}
        title="Address"
      />
      <CardBody>{address}</CardBody>
    </Card>
  );
});

export default AddressCard;
