import { FC, memo } from "react";
import styles from "./Card.module.css";
import { CardHeaderProps } from "../../../types/card.types";

const CardHeader: FC<CardHeaderProps> = memo(({ icon = null, title = "" }) => {
  return (
    <div className={styles.cardHeader}>
      {icon}
      {title ? <div className={styles.cardTitle}>{title}</div> : null}
    </div>
  );
});

export default CardHeader;
