import { FC, memo } from "react";
import { Card, CardBody, CardHeader } from "./ui/cards";
import locationImg from "../Images/location.png";
import styles from "./components.module.css";
import { PlacesCardProps } from "../types/placesCard.types";

const PlacesCard: FC<PlacesCardProps> = memo(({ venues = [] }) => {
  if (!venues?.length) return null;
  return (
    <Card>
      <CardHeader
        icon={<img src={locationImg} alt="Places" />}
        title={`Places (${venues?.length ?? 0})`}
      />
      <CardBody>
        <div className={styles.placesWrapper}>
          {venues?.slice(0, 2)?.map((item) => {
            return (
              <div key={item?.venue_id} className={styles.placesCard}>
                <div className={styles.placeImgWrapper}>
                  <img
                    src={item.image_url}
                    alt=""
                    className={styles.placeImg}
                  />
                  <div className={styles.placeName}>{item.name}</div>
                </div>
              </div>
            );
          })}

          {venues?.length > 2 && (
            <div className={styles.placeCount}>+{venues?.length - 2}</div>
          )}
        </div>
      </CardBody>
    </Card>
  );
});

export default PlacesCard;
